<template>
  <div class="login-form">
    <v-card class="elevation-12">
      <v-img
        :src="require('@/assets/Staticlogo_katibworks.webp')"
        width="150"
        class="mx-auto"
        alt="Opis slike"
      ></v-img>
      <v-card-title class="headline">Enter a new password</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="submitForm">
          <v-text-field
            v-model="password"
            :label="showPassword ? 'New password' : 'New password'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[(v) => !!v || 'Enter a new password']"
            autocomplete="new-password"
          >
            <template v-slot:append>
              <v-btn icon @click="toggleShowPassword">
                <v-icon>
                  {{ showPassword ? "mdi-eye-off" : "mdi-eye" }}
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <v-text-field
            v-model="confirmPassword"
            label="Confirm password"
            type="password"
            :rules="[
              (v) => !!v || '',
              (v) => v === password || 'Passwords do not match',
            ]"
          ></v-text-field>
          <v-btn type="submit" color="primary mt-2">RESET PASSWORD</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
  >
    {{ snackbar.message }}
    <v-btn text @click="snackbar.show = false">Close</v-btn>
  </v-snackbar>
  <v-snackbar v-model="showSnackbar" bottom color="success" :timeout="2000">
    Text copied!
  </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
        showSnackbar: false,
    snackbar: {
      show: false,
      message: "",
      timeout: 3000,
      color: "success",
    },
      password: "",
      confirmPassword: "",
      showPassword: false,
      token: "",
    };
  },
  created() {
    const url = window.location.href;
    const token = url.substring(url.lastIndexOf("/") + 1);
    this.token = token;
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        const payload = {
          password: this.password,
          token: this.token,
        };
        console.log(this.password, this.token, 'reset password')

        axios
          .post(
            "https://qira-worker.mwtatooine.net/api/frontend/resetPassword",
            payload
          )
          .then((response) => {
            console.log(response.data);
            this.snackbar.message = "You have successfully reset your password";
            this.snackbar.color = "success";
            this.snackbar.show = true;
            this.$router.push({ name: "home" });
          })
          .catch((error) => {
            console.log(error);
            this.snackbar.message = "Failed";
            this.snackbar.color = "error";
          });
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
