<template>
    
    <form
      class="d-flex pos-absolute"
      style="z-index: 8;"
      role="search"
    >
    <v-text-field
      label="Search within your and group transcripts"
      append-icon="mdi-magnify"
      clearable
      hide-details
      class="search-field"
      v-model="textTerm"
      @input="searchText"
      type=""
      ria-label="Pretraživač"
      @focus="openSearchBox"
      @blur="closeSearchBox"
      @keyup.enter="doSearch"
    ></v-text-field>
      <div class="searchBox" v-show="isFocused && textTerm !== ''">
        <div
          class="searchTerms"
          v-for="search in searches"
          :key="search.id"
          @click="routerLink(search)"
        >
          <div class="textSearch">
            <div class="d-flex justify-content-between align-items-center">
            <p class="searchDate">⌚ {{ formatDateUpload(search.created_at) }}</p>
            <p class="mx-2">
                <span class="mdi mdi-record-circle-outline text-danger"></span>
                 {{ formatTime(search.length) }}
            </p>
          </div>
            <v-card-text>
              {{ search.short_title }}
            </v-card-text>
            <v-card-actions>
                <v-chip
                  class="keyword-chip"
                  small
                  v-for="(keyword, index) in getKeywords(search.keywords)"
                  :key="'keyword-' + index"
                  >{{ keyword }}</v-chip
                >
                <v-chip
                  class="name-chip"
                  small
                  v-for="(people, index) in getPeople(search.people)"
                  :key="'person-' + index"
                  >{{ people }}</v-chip
                >
             </v-card-actions>
          </div>
        </div>
      </div>
    </form>
  </template>
    
    <script>
  import axios from "axios";
  
  export default {
    name: "Search-nav",
    data() {
      return {
        textTerm: "",
      searches: "",
      isFocused: false,
      searchBoxTimeout: null,
      };
    },
    methods: {
      async searchText() {
        if (this.textTerm.length >= 3) {
          try {
            const userId = JSON.parse(localStorage.getItem("user")).id;
            const roleId = JSON.parse(localStorage.getItem("user")).role_id;
            console.log(userId, this.textTerm, roleId, 'rankeleaaaa')
            const response = await axios.post(
              "https://qira-worker.mwtatooine.net/api/v2/frontend/search",
              {
                "userId" : userId,
                "search" : this.textTerm,
                "limit" : 15,
                "roleId" : roleId
              }
            );
            this.searches = response.data.result.transcription;
            console.log(this.searches, 'search');


          } catch (error) {
            console.log(error);
          }
        } else if (this.textTerm.length === 0) {
          this.searches = "";
        }
      },
      openSearchBox() {
      this.isFocused = true;
      clearTimeout(this.searchBoxTimeout);
    },
    closeSearchBox() {
      // Koristite setTimeout za zatvaranje searchBox-a nakon 1 sekunde
      this.searchBoxTimeout = setTimeout(() => {
        this.isFocused = false;
      }, 200);
    },
      getKeywords(keywordsString) {
      if (!keywordsString) {
        console.log("Keywords string is null or undefined.");
        return null;
      }

      try {
        const keywordsArray = JSON.parse(keywordsString);

        if (!Array.isArray(keywordsArray)) {
          console.log("Parsed keywords is not an array.");
          return null;
        }

        return keywordsArray.map((keyword) => keyword.trim());
      } catch (error) {
        console.log("Error parsing keywords.", error);
        return null;
      }
    },
    getPeople(keywordsString) {
      if (!keywordsString) {
        console.log("Keywords string is null or undefined.");
        return null;
      }

      try {
        const keywordsArray = JSON.parse(keywordsString);

        if (!Array.isArray(keywordsArray)) {
          console.log("Parsed keywords is not an array.");
          return null;
        }

        return keywordsArray.map((keyword) => keyword.trim());
      } catch (error) {
        console.log("Error parsing keywords.", error);
        return null;
      }
    },
    formatTime(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      // Formatiraj u "hh:mm:ss"
      return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    },
    formatDateUpload(dateString) {
      const date = new Date(dateString);
      
      const year = date.getFullYear();
      const month = date.toLocaleString('en-US', { month: 'short' });
      const day = date.getDate();
      
      let hour = date.getHours();
      const minute = date.getMinutes().toString().padStart(2, '0');


      hour = hour.toString().padStart(2, '0');

      return `${day} ${month} ${year}, ${hour}:${minute}`;
    },
      routerLink(item) {
        this.$router.push({ name: "oneTranscription", params: { id: item.id } });
        this.textTerm = "";
      },
    },
  };
  </script>

<style scoped>
span.keyword-chip.v-chip.v-chip--no-color.theme--light.v-size--small, span.name-chip.v-chip.v-chip--no-color.theme--light.v-size--small {
    margin-bottom: 5px;
}
.v-application p {
    margin-top: 18px;
}
.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 9px;
}
.v-card__actions {
    flex-flow: wrap;
}
  input.form-control {
    background: #141414;
    transition: 0.3s;
  }
  .form-control:focus {
    transition: 0.3s;
    box-shadow: none;
    width: 250px;
    background: #212529;
  }
  
  input.form-control:hover {
    transition: 0.3s;
    background: #212529;
  }
  .searchBox {
    width: 520px;
    max-height: 570px;
    overflow: auto;
    box-shadow: 0px 4px 16px #EAE2FD !important;
    border-radius: 11px;
    background: #FBFAFF;
    position: absolute;
    top: 46px;
    left: 0px;
    z-index: 999;
  }
  @media only screen and (min-width: 950px) {
    .pos-absolute{
      position: relative;
    } 
  }
  
  .searchTerms {
    display: flex;
    box-shadow: 0px 4px 16px #EAE2FD !important;
    border-radius: 11px;
    background: #FBFAFF;
    width: 90%;
    margin: 15px auto;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  .searchTerms:hover {
    background: rgb(54 51 226 / 10%);
    transition: 0.2s;
  }
  .searchTerms img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .searchBox::-webkit-scrollbar {
    background: #FBFAFF;
    width: 10px;
    display: none;
  }
  .searchBox::-webkit-scrollbar-thumb {
    background: #FBFAFF;
    display: none;
  }
  .textSearch {
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: left;
    margin: 0 5px 0 20px;
  }
  .searchTitle {
    font-size: 16px;
    font-weight: 700;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  </style>