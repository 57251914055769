import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const driverObj = driver({
  showProgress: true,
  steps: [
    {
      element: '#adminPanel',
      popover: {
        title: 'Admin Panel',
        description: 'Opis Admin Panel',
      }
    },
  ]
});

export function startTour() {
  driverObj.drive();
  // if (!localStorage.getItem('tourShown')) {
  //   driverObj.drive();

  //   localStorage.setItem('tourShown', 'true');
  // }
}
