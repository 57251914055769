<template>
  <div class="login-form">
    <v-card class="elevation-12">
      <v-img
        :src="require('@/assets/Staticlogo_katibworks.webp')"
        width="150"
        class="mx-auto"
        alt="Opis slike"
      ></v-img>
      <v-card-title class="headline">Login</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field label="Email" v-model="username"></v-text-field>
          <v-text-field
            v-if="!forgotPassword"
            label="Lozinka"
            type="password"
            v-model="password"
          ></v-text-field>
          <div v-if="!forgotPassword" class="grey--text text-darken-1 mb-2">
           <a href="#" @click="forgotPassword = true"> Forgot password </a>
          </div>
          <div v-else>
            <v-btn color="primary" @click="sendResetRequest">Submit</v-btn>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="spacer"></div>
        <v-btn color="success" @click="login">
          {{ forgotPassword ? 'Back to Login' : 'Submit' }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
  >
    {{ snackbar.message }}
    <v-btn text @click="snackbar.show = false">Close</v-btn>
  </v-snackbar>
  <v-snackbar v-model="showSnackbar" bottom color="success" :timeout="2000">
    Text copied!
  </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "LoginUser",
  data() {
    return {
      showSnackbar: false,
    snackbar: {
      show: false,
      message: "",
      timeout: 3000,
      color: "success",
    },
      username: "",
      password: "",
      forgotPassword: false,
    };
  },
  methods: {
    async login() {
      if (this.forgotPassword) {
        this.forgotPassword = false;
        return;
      }

      try {
        const response = await fetch(
          "https://qira-worker.mwtatooine.net/api/frontend/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: this.username,
              password: this.password,
            }),
          }
        );

        const data = await response.json();
        console.log(data.result, "why");
        if (data.success) {
          localStorage.setItem("user", JSON.stringify(data.result.user));

          this.$emit("login-success");
          window.location.reload();
        } else {
          alert("Login unsuccessful.");
        }
      } catch (error) {
        console.error(error);
        alert(
          "An error occurred while signing in. Please check again."
        );
      }
    },
    async sendResetRequest() {
      try {
        const response = await fetch(
          "https://qira-worker.mwtatooine.net/api/frontend/resetPassword",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: this.username,
            }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          this.snackbar.message = "Your password reset request has been successfully sent, please check your email.";
          this.snackbar.color = "success";
          this.snackbar.show = true;
        } else {
          alert("An error has occurred.");
          console.error(data.error);
          this.snackbar.message = "Failed";
            this.snackbar.color = "error";
        }
      } catch (error) {
        console.error(error);
        alert(
          "An error has occurred."
        );
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.v-snack__wrapper.v-sheet.theme--dark.success {
  max-width: 100%;
  background: green!important;
}
.v-snack__wrapper.v-sheet.theme--dark.success .v-snack__content {
  max-width: 100%;
  background: green!important;
}


.v-card__title {
  text-align: center;
}
a {
  color: #9a9a9a;
}

.spacer {
  flex: 1;
}
</style>
