import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios';

export default new Vuex.Store({
  state: {
    groups: [],
    users: [],
    folders: [],
  },
  getters: {
    getGroups: (state) => state.groups,
    getUsers: (state) => state.users,
    getFolders: (state) => state.folders,
  },
  mutations: {
    setGroups: (state, groups) => {
      state.groups = groups.result.groups;
    },
    setUsers: (state, users) => {
      state.users = users.result.users;
    },
    setFolders: (state, folders) => {
      state.folders = folders.result.folders;
    },
  },
  actions: {
    async fetchFolders({ commit }) {
      try {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        const response = await axios.post('https://qira-worker.mwtatooine.net/api/frontend/getAllFoldersForUser', {
          "userId": userId
        });

        if (response.status === 200) {
          commit('setFolders', response.data);
        } else {
          console.error('Error fetching folders:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching folders:', error);
      }
    },
    async fetchGroups({ commit }) {
      try {
        const organization_id = JSON.parse(
          localStorage.getItem("user")
        ).organization_id;
        const response = await axios.post(
          'https://qira-worker.mwtatooine.net/api/frontend/getGroupsOfOrganization',
          {
            "organizationId": organization_id,
          }
        );

        if (response.status === 200) {
          commit('setGroups', response.data);
        } else {
          console.error('Error fetching groups:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    },
    async fetchUsers({ commit }) {
      try {
        const organization_id = JSON.parse(
          localStorage.getItem("user")
        ).organization_id;
        const response = await axios.post(
          'https://qira-worker.mwtatooine.net/api/frontend/getUsersByOrganization',
          {
            "organization_id": organization_id,
          }
        );

        if (response.status === 200) {
          commit('setUsers', response.data);
        } else {
          console.error('Error fetching users:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
  },
  modules: {},
});

