<template>
  <div>
    <img
      src="https://app-works.app/image/cache/original/files/images/transcript-logo.png" width="250"
      class="img-fluid mx-auto mt-8 d-block"
    />
  </div>
</template>

<script>

export default {
  name: "HomeView",

  components: {

  },
};
</script>
