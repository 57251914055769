<template>
  <v-dialog v-model="showModal" max-width="500px" persistent>
    <v-card>
      <!-- <v-card-title class="headline my-2">We have prepared new functionalities for you!</v-card-title> -->
      <v-card-text style="padding: 0!important;">
        <v-stepper v-model="currentStep">
          <!-- Klikabilni koraci -->
          <v-stepper-header>
            <!-- Step 1 -->
            <v-stepper-step
              :complete="currentStep > 1"
              step="1"
              color="#6c62ef"
              @click="currentStep = 1"
            >
              Step 1
            </v-stepper-step>
            <v-divider></v-divider>
            <!-- Step 2 -->
            <v-stepper-step
              :complete="currentStep > 2"
              step="2"
              color="#6c62ef"
              @click="currentStep = 2"
            >
              Step 2
            </v-stepper-step>
            <v-divider></v-divider>
            <!-- Step 3 -->
            <v-stepper-step
              step="3"
              color="#6c62ef"
              @click="currentStep = 3"
            >
              Step 3
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Step 1 Content -->
            <v-stepper-content step="1">
              <h5>Introducing Speaker recognition</h5>
              <p class="mb-8 text-welcome">You can now enable Speaker recognition during file upload, to identify different speakers in transcriptions. Note that this option slows down processing, so we recommend activating it only when needed.</p>
              <div class="height-1">
              <img class="show" src="https://app-works.app/image/cache/extra-large/files/images/screenshot-2024-09-13-115325.png?crop=true">
              </div>
              <v-btn color="primary" @click="nextStep">Next</v-btn>
            </v-stepper-content>

            <!-- Step 2 Content -->
            <v-stepper-content step="2">
              <h5>New Interactive audio playback and editing</h5>
              <p class="mb-8 text-welcome">We have revamped audio player: Play your entire transcription without interruptions of chunking, but click on any sentence to listen to that specific part. Right-click on the text to make instant changes.</p>
              <div class="height-2">
              <img class="show" src="https://app-works.app/image/cache/large/files/images/screenshot-2024-09-13-114813.png?crop=true">
              </div>
              <v-btn color="primary" @click="nextStep">Next</v-btn>
            </v-stepper-content>

            <!-- Step 3 Content -->
            <v-stepper-content step="3">
              <h5>Need assistance or have feedback?</h5>
              <p class="mb-8 text-welcome">We have added a support icon for your convenience to the bottom left corner of the screen. Click it to ask questions, report issues, or share feedback and ideas for future improvements. Our team is here to help and value your input!</p>
              <div class="height-3">
              <img class="show" src="https://app-works.app/image/cache/extra-large/files/images/screenshot-2024-09-13-114921.png?crop=true">
              </div>
              <v-btn color="primary" @click="closeModal">Close</v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showModal: false, // Kontroliše prikaz modala
      currentStep: 1, // Trenutni korak u stepperu
    };
  },
  mounted() {
    const hasSeenModal = localStorage.getItem("hasSeenWelcomeModal");
    if (!hasSeenModal) {
      this.showModal = true;
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      localStorage.setItem("hasSeenWelcomeModal", true); // Postavi lokalno skladište
    },
    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep += 1; // Prelaz na sledeći korak
      }
    },
  },
};
</script>
<style>
.v-stepper__header {
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, .2)!important;
}
.show {
    width: 100%!important;
    margin: 0px 0 20px 0;
}
.text-welcome {
  font-weight: 400!important;
}
.height-1 {
  height: 324px;
}
</style>
