<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip right> <!-- Dodali smo `right` umesto `bottom` -->
          <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <v-btn
              class="mx-2 chat-bot"
              fab
              dark
              color="purple"
              v-bind="{ ...attrs, ...tooltipAttrs }"
              v-on="{ ...on, ...tooltipOn }"
            >
              <v-icon dark>mdi-chat</v-icon>
            </v-btn>
          </template>
          <span>Click to contact support</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">How can we help you?</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Your email adress"
                  v-model="email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="['Error', 'Question', 'Suggestion']"
                  label="Select reason"
                  required
                  v-model="typeOfChat"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Subject"
                  required
                  v-model="subject"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="text"
                  placeholder="Explain what you want"
                  label="Text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-file-input
                  label="Upload image"
                  v-model="imageUrl"
                  prepend-icon="mdi-camera"
                  ref="fileInput"
                  @change="uploadImage"
                ></v-file-input>

                <img
                  :src="this.imageUrl"
                  style="height: 50px; margin-left: 15px;"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="sendMessage()">
            Send
          </v-btn>
          <v-btn color="error darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    subject: "",
    email: "",
    text: "",
    typeOfChat: "",
    imageUrl: "",
    textPackage: "",
    showSnackbar: false,
    snackbar: {
      show: false,
      message: "",
      timeout: 3000,
      color: "success",
    },
  }),
  computed: {
    instancePart() {
      return "This request was submitted from the instance: " + this.instance;
    },
    typeOfChatPart() {
      return "The type of this request is: " + this.typeOfChat;
    },
    instance() {
      return "Litteraworks";
    },
  },
  methods: {
    uploadImage() {
    const file = this.$refs.fileInput.$refs.input.files[0]; // Pristupanje direktno izabranom fajlu
    if (!file) {
      console.error('Nema fajla za otpremanje.');
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    axios.post("https://app-works.app/api/v1/uploadImageForSupport", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      this.imageUrl = response.data.response.imagePath;
      console.log("Slika je uspešno otpremljena:", this.imageUrl);
    })
    .catch((error) => {
      console.error("Došlo je do greške pri otpremanju slike:", error);
    });
  },

    sendMessage() {
      console.log(
        this.email,
        this.subject,
        this.instance,
        this.text,
        this.typeOfChat,
        this.instancePart,
        this.typeOfChatPart
      );


      this.textPackage = this.instancePart + "<br>" + this.typeOfChatPart + "<br>" + this.text;

      if (this.imageUrl && this.imageUrl.trim() !== '') {
        var newImageUrl = this.imageUrl;
        var imageTextPart = "<b>The image sent with the request : </b>";
        var imagePart = "<div><img style='width: 100%;' src='" + newImageUrl + "' alt='attachment_image'><br></div>";
        this.textPackage = this.textPackage + "<br><br>" + imageTextPart + "<br><br>" + imagePart;
      }

      var url = "https://support.app-works.app/api/conversations";

      var headers = {
        "X-FreeScout-API-Key": "972aa9892536b664d1e9428afb5db66c",
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      var body = {
        type: "email",
        mailboxId: 4,
        subject: this.subject,
        customer: {
          email: this.email,
        },
        threads: [
          {
            text: this.textPackage,
            type: "message",
            user: 5,
          },
        ],
        imported: false,
        assignTo: 0,
        status: "active",
      };

      axios
        .post(url, body, {
          headers: {
            ...headers,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.snackbar.message =
            "You have successfully sent us a message, we will get back to you shortly!";
          this.snackbar.color = "success";
          this.snackbar.show = true;
          this.dialog = false;
          this.subject = "";
          this.email = "";
          this.text = "";
          this.typeOfChat = "";
          this.imageUrl = "";
          this.textPackage = "";
        })
        .catch((error) => {
          console.error(error);
          this.snackbar.message = "Error";
          this.snackbar.color = "error";
          this.snackbar.show = true;
        });

      console.log(url, headers, body);
    },
  },
};
</script>
<style scoped>
.chat-bot {
    position: absolute;
    left: 2px;
    bottom: 10px;
}

textarea, .textarea {
  background-color: transparent;
  border-style: none;
  box-shadow: none;
}
</style>


