import Vue from 'vue'
import jQuery from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify'
import CookieLaw from 'vue-cookie-law'
import * as Sentry from "@sentry/vue";

Vue.component('cookie-law', CookieLaw)

window.$ = window.jQuery = jQuery

Vue.use(Vuetify)

// Originalna console.log funkcija
window.originalConsoleLog = console.log;

// Isključivanje console.log poziva
function disableConsoleLog() {
  console.log = function () {};
}

// Isključivanje console.log poziva prilikom učitavanja aplikacije
disableConsoleLog();

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://4f2a27f562e2069aadc174739e115ff0@o4505243997634560.ingest.sentry.io/4505799839055872",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
